import React from "react";
import { AnchorProps } from "../types/types";

import { AnchorEmbeddedURL } from "../data/data";

const AnchorEmbeded = () => {

    return (
        <iframe title="Listen to our Podcast on Anchor" src={AnchorEmbeddedURL} scrolling="no"></iframe>
    )

}

export default AnchorEmbeded;
