import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-scroll";
import { ReactComponent as WaveyLinesIllustration } from "../assets/waveyLines.svg";
import { ReactComponent as Illustration } from "../assets/FPF_Logo.svg";
import { MainDisplayProps } from "../types/types";
import AnchorEmbeded from "./AnchorEmbeded";

const MainDisplay: React.FC<MainDisplayProps> = ({
  greetingText,
  greetingDescription,
  buttonText,
}) => {
  return (
    <div
      className="relative min-h-screen pt-0 mt-0 antialiased hero bg-gradient-to-br from-primary to-secondary text-primary-content"
      id="home"
    >
      <div className="fill-current custom-shape-divider-bottom-1628871186">
        <WaveyLinesIllustration />
      </div>

      <div className="text-justify hero-content text-neutral-content flex flex-wrap">
        <Illustration
          className="fill-current flex w-3/4 h-1/2 md:w-1/2"
        />
        <div className="max-w-md ml-10">
          <motion.div
            animate={{ scale: [0.5, 1] }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="mb-5 text-5xl font-bold ">{greetingText}</h1>
            <p className="mb-5">{greetingDescription}</p>
            {/* <Link
              to={"experience"}
              spy={true}
              smooth={true}
              duration={500}
              className="transition rounded-full cursor-pointer btn btn-primary"
            >
              {buttonText}
            </Link> */}
          </motion.div>
          <AnchorEmbeded />
        </div>
      </div>
    </div>
  );
};

export default MainDisplay;
