import React from "react";
import { Helmet } from "react-helmet";
import { URL } from "../data/data";
import { SEOProps } from "../types/types";

const Seo: React.FC<SEOProps> = ({
  lang,
  url,
  metaDescription,
  keywords,
  title,
  author,
  image: metaImage,
  theme,
}) => {
  // const image = metaImage && metaImage.src ? `${metaImage.src}` : null;
  return (
    <Helmet>
      <html data-theme={theme} data-react-helmet="true" />
      <title>{title}</title>
      <meta name="title" content={title} data-react-helmet="true" />
      <meta
        name="description"
        content={metaDescription}
        data-react-helmet="true"
      />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta
        property="og:image"
        content={metaImage.src}
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        content={metaDescription}
        data-react-helmet="true"
      />
      <meta property="og:url" content={URL} data-react-helmet="true" />

      {/* Added from favicon generator */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
      <link rel="manifest" href="/site.webmanifest"/>
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
      <meta name="msapplication-TileColor" content="#da532c"/>
      <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
  );
};

export default Seo;
