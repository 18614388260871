import Image from "../assets/FPF_Circular_Logo.png";
import {
  ImageType,
  MenuProps,
  ProjectCardProps,
  SkillsProps,
  TimelineProps,
} from "../types/types";
export const URL = "https://foolprooffootball.com";
export const NAME = "FoolProof Football";

export const SECTIONS = ["Home", "Experience", "Projects", "Skills"];
export const HERO_IMAGE = "";
export const CTA_TEXT = "Know More";
export const MENU: MenuProps[] = [
  // { key: 1, route: "home", name: SECTIONS[0] },
  // { key: 2, route: "experience", name: SECTIONS[1] },
  // { key: 3, route: "projects", name: SECTIONS[2] },
  // { key: 4, route: "skills", name: SECTIONS[3] },
];

export const AnchorEmbeddedURL = "https://anchor.fm/foolproof-football/embed";

export const GREETING_TEXT = "FoolProof Football";
export const GREETING_DESCRIPTION =
  "4 guys who like football and talking shit.";

export const EXPERIENCE: TimelineProps[] = [
  {
    key: 1,
    company: "Google",
    position: "Software Engineer",
    logo: "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png",
    start: "2015-01-01",
    end: "2016-01-01",
    description: "I worked on the frontend of Google's new search engine.",
  },
  {
    key: 2,
    company: "Facebook",
    position: "Software Engineer",
    logo: "https://logos-world.net/wp-content/uploads/2020/04/Facebook-Logo.png",
    start: "2015-01-01",
    end: "2016-01-01",
    description: "I worked on the frontend of Facebook.",
  },
  {
    key: 3,
    company: "Microsoft",
    position: "Software Engineer",
    logo: "https://www.backbase.com/wp-content/uploads/2020/05/Microsoft-Logo-PNG-Transparent.png",
    start: "2015-01-01",
    end: "2016-01-01",
    description: "I worked on the frontend of Microsoft's new search engine.",
  },
  {
    key: 4,
    company: "Netflix",
    position: "Software Engineer",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/2560px-Netflix_2015_logo.svg.png",
    start: "2015-01-01",
    end: "2016-01-01",
    description: "I worked on the frontend of Netflix.",
  },
];
export const KEYWORDS = [
  "Developer",
  "Big Aul Cunt",
  "FPF",
  "My Balls",
];
export const IMAGE: ImageType = {
  src: Image,
  height: "600",
  width: "314",
};

export const projects: ProjectCardProps[] = [
  {
    id: 1,
    projectName: "FoolProofFootball",
    projectDescription:
      "Our Website",
    projectImageLogo: `.........`,
    link: "https://foolprooffootball.com",
    buttonText: "View Project",
    tech: ["React", "TailwindCSS", "CSS", "Javascript"],
  }
];
/**
 *  @type {SkillsProps}
 * skills
 * Kept level as random but you can hard code it if you want
 */
export const skills: SkillsProps[] = [
  {
    id: 1,
    skill: "Javascript",
    level: Math.floor(Math.random() * 100) + 1,
  },
  {
    id: 2,
    skill: "React",
    level: Math.floor(Math.random() * 100) + 1,
  },
  {
    id: 3,
    skill: "CSS",
    level: Math.floor(Math.random() * 100) + 1,
  },
  {
    id: 4,
    skill: "HTML",
    level: Math.floor(Math.random() * 100) + 1,
  },
  {
    id: 5,
    skill: "Java",
    level: Math.floor(Math.random() * 100) + 1,
  },
  {
    id: 6,
    skill: "Googling",
    level: Math.floor(Math.random() * 100) + 1,
  },
];
